//Common style
.page-header {
  width: 100%;
  & h2 {
    border-bottom: 2px solid $secondColor;
    padding-bottom: .5rem;
    margin: 0;
  }
  & p {
    margin: 5px;
    text-align: end;
  }
}
/** 01 - About me style */
.aboutme {
  display: flex;
  .bg-image {
    background-image: url('../assets/images/dev-desk.jpg');
    width: 50%;
    height: 100vh;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
  }
  & img {
    width: 45%;
    max-width: 45%;
    height: 100vh;
    max-height: 100vh;
  }
  .description {
    width: 55%;
    overflow: auto;
    height: 100vh;
    max-height: 100vh;
    padding: 3rem 2rem;
    & h1 {
      border-bottom: 2px solid $themeColor;
      width: fit-content;
    }
    .intro-skills {
      display: flex;
      align-items: center;
      & p {
        margin: .5rem 1rem;
      }
      & span {
        margin-top: 5px;
      }
    }
    .download-button {
      width: 100%;
      padding: 2rem 0;
      text-align: center;
      & button {
        padding: .7rem 2rem;
        border-radius: 25px;
        border: 2px solid $themeColor;
        background-color: transparent;
        color: $themeColor;
        cursor: pointer;
        &:hover {
          background-color: $themeColor;
          color: white;
        }
        & a {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}
/** 02 - Experiences style */
.experience {
  padding: 2rem;
}

/** 03 - Skills style */
.skills {
  padding: 2rem;
  .skill-progress {
    margin-bottom: 1rem;
    .skill-score {
      display: flex;
      justify-content: space-between;
      margin-bottom: .5rem;
      padding: 0 0.4rem;
    }
    .progress-container {
      border: 1px solid $themeColor;
      border-radius: 25px;
      height: 10px;
      padding: .2rem;
      display: flex;
      align-items: center;
      .progress-bar {
        display: flex;
        align-items: center;
        background-color: $themeColor;
        border: 2px solid $themeColor;
        border-radius: 25px;
      }
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
    .col {
      width: 47%;
      padding: 0 1rem;
      .skill-block {
        margin-left: 1rem;
      }
      .title {
        border-bottom: 2px solid $themeColor;
        width: fit-content;
        margin-top: 3rem;
      }
    }
  }
}

.timeline {
  width:100%;
  margin-top: 25px;
  padding-top: 5px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left:calc(25%);
    bottom: 0px;
    width: $border-width;
    background: #ddd;
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  .entry {
    clear: both;
    text-align: left;
    position: relative;
    .title {
      margin-bottom: .5em;
      float: left;
      width: 25%;
      padding-right: $gutter;
      text-align: right;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: $dot-diameter;
        height: $dot-diameter;
        border: $border-width solid $themeColor;
        background-color: $secondColor;
        border-radius:100%;
        top: 15%;
        right: -9px;
        z-index: 15;
      }
    }
    .body {
      margin: 0 0 3em;
      float: right;
      width: 75%;
      padding-left: $gutter;
    }
  }
}

//contact page style
.contact {
  padding: 2rem;
  min-height: 100%;
  height: 100%;
  .contact-block {
    display: flex;
    .contact-form {
      width: 50%;
      padding: 1rem 2rem;
      .contact-fields {
        .input-group {
          display: flex;
          flex-direction: column;
          margin-bottom: 1rem;
          & input, textarea {
            background-color: transparent;
            border-radius: 5px;
            border: 1px solid $silver;
            padding: .7rem;
            color: $silver;
          }
        }
        .button-block {
          width: 50%;
          margin: auto;
          & button {
            margin: 1rem 0;
            width: 100%;
            background-color: transparent;
            border: 1px solid $themeColor;
            color: white;
            padding: 1rem;
            border-radius: 25px;
            cursor: pointer;
            &:hover {
              background-color: $themeColor;
            }
          }
        }
      }
    }
    .contact-detail {
      width: 50%;
      padding: 2rem;
      margin-top: 1rem;
      .contact-row .contact-card {
        display: flex;
        align-items: center;
        border: 1px solid $silver;
        padding: 1rem 2rem;
        margin-bottom: 1rem;
      }
    }
  }
}

.error-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  .content {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

@media (max-width: 990px){
  //About me page responsive design
  .aboutme {
    flex-direction: column;
    width: 100%;
    .bg-image {
      width: 100%;
      height: 50vh;
    }
    & img {
      width: 100%;
      max-width: 100%;
      height: 50%;
    }
    .description {
      width: 100%;
      height: 100%;
      max-height: 100%;
      overflow: hidden;
    }
  }
  //Experiences page responsive style
  .experience {
    padding-top: 80px;
    max-width: 850px;
    margin: auto;
  }
  //Skills page responsive design
  .skills {
    padding-top: 70px;
    max-width: 850px;
    margin: auto;
    .row {
      flex-direction: column;
      .col {
        width: 100%;
      }
    }
  }
  //timeline responsive design
  .timeline {
    &:before {
      left: 0;
    }
    .entry {
      .title {
        float: none;
        padding-left: 30px;
        text-align: left;
        width: 100%;
        &::before {
          left: -6px !important;
        }
        & h3, p {
          margin: 0;
        }
      }
      .body {
        float: left;
        width: 100%;
      }
    }
  }
  //contact page responsive style
  .contact {
    padding-top: 70px;
    max-width: 850px;
    margin: auto;
    .contact-block {
      flex-direction: column-reverse;
      .contact-form {
        width: 100%;
        padding: 0;
      }
      .contact-detail {
        width: 100%;
        padding: 0;
        .contact-row {
          .contact-card {
            flex-direction: column;
          }
        }
      }
    }
  }
}