.show {
  display: block !important;
}
.hide {
  display: none;
}
.mobile-view {
  width: 50% !important;
}

.sidebar {
  position: fixed;
  width: 170px;
  max-width: 320px;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 10px 0 0;
  background-color: #222;
  overflow: auto;
  z-index: 50;
  -webkit-box-shadow: 5px 2px 15px 2px #000000; 
  box-shadow: 5px 2px 15px 2px #000000;
  .sidebar-content {
    width: 100%;
    max-width: 320px;
    height: 100%;
    left: 0;
    z-index: 1001;
    display: table;
    position: relative;
    padding-bottom: 50px;
    visibility: visible;
    -webkit-transition: all 0s ease-in-out;
    -moz-transition: all 0s ease-in-out;
    -o-transition: all 0s ease-in-out;
    -ms-transition: all 0s ease-in-out;
    transition: all 0s ease-in-out;
    .avatar {
      width: 90px;
      height: 90px;
      margin: 20px auto;
      max-width: 110px;
      text-align: center;
      background-image: url('../assets/images/me.jpg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .name {
      margin: 1rem;
      text-align: center;
      & a {
        text-decoration: none;
        color: white;
        font-size: 23px;
        font-weight: 600;
      }
    }
    .copyright {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 5px 10px 15px;
      line-height: 14px;
      font-size: 12px;
      text-align: center;
      color: #aaa;
    }
    .social-media {
      margin: 1rem 0;
      display: flex;
      justify-content: space-evenly;
      & a {
        color: $txtColor1;
      }
    }
    &::after{
      content: " ";
      display: table;
    }
  }
  @media (max-width: 990px) {
    display: none;
    top: 50px;
  }
  @media (min-width: 991px) {
    display: block;
    width: 160px;
  }
}
.nav-item {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.85em;
  color: #f5f5f5;
  letter-spacing: 0;
  display: block;
  position: relative;
  padding: 10px 20px;
  text-align: center;
  position: relative;
  text-decoration: none;
  border-bottom: 1px solid #333;
  outline: none;
}

.nav-item::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  content: '';
  width: 3px;
  height: 100%;
  margin: 0 auto;
}
.nav-item-active::after {
  background-color: $themeColor;
}

.mobile-header {
  position: fixed;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  height: 50px;
  background-color: $mainColor;
  border-bottom: 1px solid $silver;
  left: 0;
  right: 0;
  z-index: 99;
  padding: 0 1rem;
  color: white;
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .avatar {
      display: flex;
      align-items: center;
      .profile-image {
        width: 34px;
        height: 34px;
        background-image: url('../assets/images/me.jpg');
        background-position: center;
        background-size: cover;
        margin-right: 1rem;
      }
      .name a {
        text-decoration: none;
        color: white;
        font-size: 21px;
        font-weight: 600;
      }
    }
    & button {
      background-color: transparent;
      border: none;
      color: white;
    }
  }
  @media  screen and (min-width: 991px) {
    display: none;
  }
}