$gutter: 30px;
$border-width: 3px;
$dot-diameter: 10px;
$themeColor: #0099e5;

$mainColor: #222;
$secondColor: #333;
$silver: #666;
$txtColor1: #aaa;
$txtColor2: #d5d5d5;
$orange: #ff714a;


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&display=swap');
@import './sidebar.scss';
@import './main.scss';
@import './toastr.scss';

* {
  box-sizing: border-box;
}
body {
  position: relative;
  margin: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  background-size: cover;
  -webkit-background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  font-family: Poppins,Helvetica,sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.85em;
  color: #d5d5d5;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  .page {
    margin-left: 170px;
    height: 100%;
    position: relative;
    & main {
      position: relative;
      width: 100%;
      max-width: 100%;
      height: 100%;
      min-height: 100vh;
      padding: 0;
      z-index: 1;
      background-color: $mainColor;
      color: white;
    }
  }
}
@media only screen and (max-width: 990px) {
  .container .page {
    width: 100%;
    margin-left: 0;
  }
}
@media  (max-width: 680px) {
  
}
@media  (max-width: 990px) {
 
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: 2px;  /* Firefox */
  scrollbar-color: $orange;
}
